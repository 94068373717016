@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Colors
.primary-color {
  color: @primary-color !important;
}

// Backgrounds
.layout-background {
  background-color: @layout-body-background;
}

.ant-btn {
  &.ant-btn-link {
    color: @primary-color;
  }

  &.secondary-button {
    color: @primary-color;
    border-color: @primary-color;
  }
}

// Drawer
.ant-drawer-body {
  display: flex;
  flex-direction: column;
}
.ant-drawer.secondary {
  @white: fade(white, 75%);
  @background-color: #2a2a2a;
  .ant-drawer-header,
  .ant-drawer-body {
    background-color: @background-color;
  }
  .ant-drawer-header {
    border-bottom-color: #666666;
  }

  li,
  .ant-drawer-title,
  .ant-drawer-close,
  label,
  label .ant-form-item-tooltip,
  .ant-typography,
  .anticon:not(.anticon-edit) {
    color: @white;
  }
  .ant-alert-description li {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-input {
    & > .ant-picker-suffix,
    .anticon {
      color: @background-color;
    }
  }
  .ant-btn-text {
    color: white;
    &:hover {
      color: @primary-5;
    }
  }
  .ant-btn-dashed {
    color: white;
    border-color: white;
    background-color: inherit;

    &:hover {
      color: @primary-5;
      border-color: @primary-5;
    }
  }
  .ant-upload.ant-upload-drag {
    border-color: white;

    .ant-upload {
      color: white;
    }
  }
}

// Tabs
.ant-tabs-content-holder {
  overflow-y: scroll;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

// Table
.ant-table-cell {
  &.address {
    max-width: 300px;
  }
  & > .ant-tag {
    margin-right: 0;
  }
}

// Form
.ant-form-item-label > label {
  width: 100%;
}
.ant-form-item-explain {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// Upload
.ant-upload.ant-upload-drag {
  margin-bottom: 10px;
  .ant-upload {
    padding: 10px 0;
  }
  p.ant-upload-drag-icon {
    margin-bottom: 0px;
    & > .anticon {
      font-size: 36px;
    }
  }
  p.ant-upload-text {
    font-size: 14px;
  }
}
.ant-upload.ant-upload-select-picture-card {
  width: 165px !important;
  height: 165px !important;
}

// Picker Range
.ant-picker-range {
  width: 100%;
}

// Image
img.ant-image-img {
  max-width: 100%;
  max-height: 100%;
  width: unset;
}
.ant-table-cell > .ant-image {
  height: 15vh;
  display: inline-flex;
  align-items: center;
}

// Text
.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@primary-color: #D73716;@link-color: #e6a07c;@layout-header-background: #ffffff;@layout-sider-background: #181818;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 2px;@menu-dark-bg: #181818;@layout-trigger-background: rgba(0,0,0,0.2);@layout-header-padding: 0 25px;@layout-footer-padding: 10px 25px;@card-padding-base: 16px;