.full-height-table {
  &,
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .ant-spin-container {
    display: flex;
    flex-direction: column;
  }
  .ant-table {
    flex: 1;
  }
  .ant-table-row {
    white-space: nowrap;
  }
  .ant-pagination.ant-table-pagination {
    margin: 0.5rem 0 0;
  }
}

@primary-color: #D73716;@link-color: #e6a07c;@layout-header-background: #ffffff;@layout-sider-background: #181818;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 2px;@menu-dark-bg: #181818;@layout-trigger-background: rgba(0,0,0,0.2);@layout-header-padding: 0 25px;@layout-footer-padding: 10px 25px;@card-padding-base: 16px;