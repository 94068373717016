@import (reference) '~antd/dist/antd.less';

.ant-upload.ant-upload-drag {
  margin-bottom: 0;
  background-color: transparent !important;

  &.alone {
    width: 165px;
    height: 165px;

    & > .ant-upload.ant-upload-btn {
      padding: 0;
    }

    img,
    video {
      max-height: 165px;
    }
  }

  &.resource {
    flex: 1;

    & > .ant-upload.ant-upload-btn {
      padding: 0;
    }
  }

  &:hover .ant-upload {
    color: @primary-5 !important;
  }

  .ant-upload-drag-container {
    transition: all 0.3s @ease-in-out;
  }
}

@primary-color: #D73716;@link-color: #e6a07c;@layout-header-background: #ffffff;@layout-sider-background: #181818;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 2px;@menu-dark-bg: #181818;@layout-trigger-background: rgba(0,0,0,0.2);@layout-header-padding: 0 25px;@layout-footer-padding: 10px 25px;@card-padding-base: 16px;